import {Injectable} from '@angular/core';

import {ApiClientService} from './api-client.service';
import {Clause} from '../models/clause.model';
import {EndpointUrls} from '../constants/endpoint';
import { LampUpdateResponse } from '../models/common';

@Injectable()
export class ClauseService {

  constructor(
    private apiClisentService: ApiClientService
  ) {}

  public describeElementClause(clause: Clause): Promise<Clause> {
    if (clause.id && (clause.id.toString().indexOf('temp') > -1)) {
      delete clause.id;
    }

    return this.apiClisentService.post(EndpointUrls.clause.describeElementClause, clause);
  }

  public saveClauseDescription(clause: string, description: string, crosslingual: boolean): Promise<LampUpdateResponse> {
    return this.apiClisentService.put(`${EndpointUrls.clause.saveClauseDescription}?clause=${clause}&description=${description}&crosslingual=${crosslingual}`);
  }

  public loadClauseDescription(clause: string, identity: number): Promise<string> {
    return this.apiClisentService.get(`${EndpointUrls.clause.loadClauseDescription}?clause=${clause}&identity=${identity}`);
  }

  public listClauseDescriptions(): Promise<string[]> {
    return this.apiClisentService.get(`${EndpointUrls.clause.listClauseDescriptions}`);
  }

  public generateClause(description: string): Promise<Clause> {
    return this.apiClisentService.get(`${EndpointUrls.clause.generateClause}?description=${description}`);
  }
}
